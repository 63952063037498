<template>
  <div>
    <div class="container">
      <div class="header">
        <!-- 获客分享趋势图筛选条件 -->
        <div class="header-item">
          <el-radio-group v-model="shareClue.currentTime" @change="getArticleShareClue()">
            <el-radio-button :label="7">最近7天</el-radio-button>
            <el-radio-button :label="15">最近15天</el-radio-button>
            <el-radio-button :label="30">最近30天</el-radio-button>
            <el-radio-button :label="0">自定义时间</el-radio-button>
          </el-radio-group>
        </div>
        <div class="header-item" v-if="shareClue.currentTime == 0">
          <el-date-picker type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"
            v-model="shareClue.inputTime"
            value-format="yyyy-MM-dd"
            :default-time="['00:00:00', '23:59:59']"
            @blur="getArticleShareClue">
          </el-date-picker>
        </div>
      </div>

      <!-- 获客/分享趋势图表 -->
      <div class="header">
        <h2 style="margin-top: 10px">获客/分享趋势图</h2>
        <div class="trend-content">
          <div id="shareClue"></div>
        </div>
      </div>

      <!-- 文章详细数据 -->
      <div class="header">
        <h2 style="margin-bottom: 10px">详细数据 <div class="header-item">
          <el-input v-model="params.title" clearable placeholder="请输入文章标题"  @clear="getList(0)" suffix-icon="el-icon-search"></el-input>
        </div>
        <div class="header-item">
          <el-button type="primary"  icon="el-icon-search"  @click="getList(0)">搜索</el-button>
         </div>
        </h2>
        
     
      </div>
   
  
         <el-table
          ref="multipleTable"
          tooltip-effect ="dark"
          style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :data="articleList"
          @sort-change="changeTableSort"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <!-- 标题 -->
          <el-table-column type="index"
            width="55"></el-table-column>
          <el-table-column label="文章标题">
            <template slot-scope="scope">
              <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <!-- 来源 -->
          <el-table-column label="来源" width="100">
            <template slot-scope="scope">
              <p>{{ scope.row.flag ==1? '员工' : '系统' }}</p>
            </template>
          </el-table-column>
          <!-- 发布时间 -->
          <el-table-column label="发布时间" width="300">
            <template slot-scope="scope">
              <p>{{ scope.row.updated_at | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column label="状态" width="200">
            <template slot-scope="scope">
              <p>已发布</p>
            </template>
          </el-table-column>
          <!-- 分享次数 -->
          <el-table-column label="分享次数" width="120"  :formatter="getList" prop="count" :sortable="'custom'" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.count || 0 }}</p>
            </template>
          </el-table-column>
          <!-- 线索数 -->
           <el-table-column label="线索数" width="120" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.count || 0 }}</p>
            </template>
          </el-table-column>
          <!-- 发布、下架、删除 -->
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="goViewData(scope.row)">查看数据</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
          <el-pagination style="float: right; margin-top: 15px" layout="total, prev, pager, next, jumper"
            @current-change="getList" 
            :background="true"
            :current-page="params.pageIndex"
            :page-size="params.pageNumber"
            :total="total">
          </el-pagination>
    </div>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
export default {
  data(){
    return {
      articleList: [],     // 文章列表
      shareClue: {         // 获客/分享趋势图
        dom: null,         // 图表实例
        currentTime: 7,    // 当前选中的时间
        inputTime: [],     // 时间选择框选中的时间
      },
      params:{
        startTime:'',//开始时间
        endTime:'',//结束时间
        title:'',//文章标题
        flag:'',//1为分享次数排序
        pageIndex:1,
        pageNumber:8,
      },
      total:0,
      timer:'',
      endtimer:'',
    }
  },
  mounted(){
    this.getList(0);
    this.getArticleShareClue()

  },
  created() {

    
  },
  methods: {
    changeTableSort(column, prop, order) {
      console.log(column);
    },
    async getList(index) {
      this.params.pageIndex = index;
      //  console.log(this.shareClue.currentTime);
      if(this.shareClue.currentTime != 0) {
        this.timer =  this.getBeforeDate(this.shareClue.currentTime);
        this.endtimer =  this.getCurrentDate();
      }else {
        this.timer =  this.shareClue.inputTime[0];
        this.endtimer =  this.shareClue.inputTime[1];
      }
      this.params.startTime=this.timer;
      this.params.endTime= this.endtimer;

      if(this.params.title == '') {
        delete this.params.title;
      } 
      if(this.params.flag == '') delete this.params.flag;
      let res = await this.$ZGManager.contentAirticleCount(this.params);
      this.total = res.data.total_rows;
      this.articleList = res.data.list;
    },
    //获取前几天时间
    getBeforeDate(day) {
      let dd = new Date();
      dd.setDate(dd.getDate() - Number(day));
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1 <10 ? '0' +(dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
　　  return y + "-" + m + "-" + d;
      // console.log(dd);
    },
    //获取当前时间
    getCurrentDate() {
       let dd = new Date();
      dd.setDate(dd.getDate());
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1 <10 ? '0' +(dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
　　  return y + "-" + m + "-" + d;
    },
    // 请求获客/分享趋势图数据
    async getArticleShareClue(){
      // 计算请求时间的区间
      // console.log(this.shareClue.currentTime);
      let ranges = [{from: `now-${this.shareClue.currentTime}d/d`}];
      // console.log(ranges);
      // console.log(this.shareClue.inputTime);
      if(this.shareClue.currentTime == 0 && this.shareClue.inputTime.length < 1){
        return false;
      }else if(this.shareClue.currentTime == 0){
        ranges = [{from: this.shareClue.inputTime[0], to: this.shareClue.inputTime[1]}];
        // console.log(ranges);

      }
      this.getList(0);


      // 获取当前用户信息，并初始化数据
      let account = this.$localStorage('ZGCMS_ACCOUNT');
      let data = [];
      if(this.shareClue.currentTime != 0){
        for(let i = this.shareClue.currentTime - 1; i >= 0; i--){
          let day = this.$dayjs().subtract(i, 'day').format('YYYY-MM-DD');
          data.push({type: '获客人数', day: day, uv: 0});
          data.push({type: '分享人数', day: day, uv: 0});
        }
      }
      // console.log(data);
      // console.log(ranges);
      // 获取线索数
      let clue = await this.$ZGManager.statHistograms({
        cid: account.companyId,
        ranges: ranges,
        rt: 'article'
      }, "d", "user");
      clue.data.histograms.map((e) => {
        let index = data.findIndex(item => e.key == item.day && item.type == '获客人数');
        if(index != -1){
          data[index].uv = e.pv;
        }else{
          data.push({type: '获客人数', day: e.key, uv: e.pv});
        }
      });

      // 获取分享数
      let share = await this.$ZGManager.statHistograms({
        cid: account.companyId, 
        ranges: ranges,
        rt: 'article',
        evt: 'share'
      }, "d", "trace");
      // console.log(share);
      share.data.histograms.map((e) => {
        // console.log(e);
        let index = data.findIndex(item => e.key == item.day && item.type == '分享人数');
        if(index != -1){
          data[index].uv = e.pv;
        }else{
          data.push({type: '分享人数', day: e.key, uv: e.pv})
        }
      });
      this.renderChart(data);
    },

    // 渲染获客/分享趋势图
    renderChart(data){
      if(this.shareClue.dom){
        this.shareClue.dom.changeData(data);
      }else{
        this.shareClue.dom = new Chart({container: 'shareClue', autoFit: true, height: 300});
        this.shareClue.dom.data(data);
        this.shareClue.dom.scale({
          uv: {nice: true, alias: '人数'}
        });
        this.shareClue.dom.axis('pv', {title: {}});
        this.shareClue.dom.tooltip({showCrosshairs: true, shared: true});
        this.shareClue.dom.line().position('day*uv').color('type').shape('smooth');
        this.shareClue.dom.render();
      }
    },


    //go查看数据页
    goViewData(row) {
      let goRouter = this.$router.resolve({
        path:'/article/view',
        query:{
          title:row.title,
          flag:row.flag,
          id:row.id,
          startTime:this.params.startTime,
          endTime:this.params.endTime,
        }
      });
      window.open(goRouter.href,'_blank')
    }

  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  padding: 15px 10px;
  overflow: hidden;
}
.header{
  position: relative;
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.trend-content{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 50px;
}
</style>